<template>
  <div class="container">
    <headBar ref="headerHeight" title="包月私教卡升级" left-arrow @click-left="newAppBack"></headBar>

    <div class="w-block">
      <div v-if="upTips" class="top-tip f24 fw6 row-start-center">
        {{ upTips }}
      </div>
      <div v-if="equityCardObj.cardName" class="m-card col-between-start"
        :style="`background-image: url(${equityCardObj.backgroundImgUrl})`">
        <p class="title fw9 f44">{{ equityCardObj.cardName }}</p>
        <div class="tags row-start-center">
          <div :class="['tag', 'row-center-center', 'f20', 'fw6', equityCardObj.status === 'ACTIVE' ? 'active' : '']">{{
            equityCardObj.statusName }}</div>
          <div class="tag row-center-center f20 fw6">{{ equityCardObj.useValidityStr }}</div>
          <div class="tag row-center-center f20 fw6">{{ equityCardObj.usePlace }}</div>
          <div class="tag row-center-center f20 fw6">{{ equityCardObj.levelStr }}</div>
        </div>
      </div>
      <!-- <div v-else class="m-card col-between-start" @click="openCardList"
        :style="`background-image: url(${equityCardObj.backgroundImgUrl})`"></div> -->
      <div  v-if="!packageList.length && !equityCardList.length" class="empty">
        <img src="https://img.chaolu.com.cn/ACT/sell-membership-2022/empty.png" alt="">
        <p>当前账户暂无法升级，详细情况请咨询在线客服</p>
      </div>
      <div v-if="equityCardList.length > 1 && equityCardObj.cardName" @click="openCardList"
        class="change-btn row-center-center f24 fw6">
        <img src="https://img.chaolu.com.cn/ACT/sell-membership-2022/change-icon.png" alt="">&nbsp;更换升级的卡
      </div>
      <div class="tc f26 mt30" v-if="!packageList.length && equityCardList.length">该卡为最高档位的权益卡，无法升级</div>
    </div>
    <div class="block">
      <template v-if="packageList.length">
        <p class="f30 fw6">选择以下档位进行升级</p>
        <div class="upgrade-box">
          <div class="select-box row-around-center">
            <div :class="['select-item', 'row-center-center', 'f28', levelIndex === index ? 'active fw6' : '']"
              @click="changeLevel(index)" v-for="item, index in packageList" :key="item.price">{{ item.price }}元</div>
          </div>
          <div class="package-box">
            <div class="package-item row-between-start" v-for="item in packageList[levelIndex].equityItems"
              :key="item.itemId">
              <div class="card flex-none row-start-center f20 fw7" :style="`background-image: url(${item.img})`">
                {{ item.itemName }}
              </div>
              <div class="right flex-auto">
                <div class="row-start-center">
                  <p class="f30 fw6">{{ item.itemName }}</p>
                </div>
                <div class="row-start-center">
                  <div class="row-center-center tag f18 fw6">{{ item.useValidityStr }}</div>
                  <div class="row-center-center tag f18 fw6">{{ item.applyCityName }}</div>
                </div>
                <div class="info row-between-end">
                  <div class="price flex-auto row-start-end">
                    <div class="left">
                      <p class="f22">原价</p>
                      <p class="p2 f30 fw6"><span class="f22">¥</span>{{ item.originPrice }}</p>
                    </div>
                    <div class="right" style="margin-left:10px ;">
                      <p class="f22">补差价</p>
                      <p class="p2 f30 fw6"><span class="f22">¥</span>{{ item.mendPrice }}</p>
                    </div>
                  </div>
                  <div @click="upgrade(item)" class="btn row-center-center f24 fw6 flex-none">去升级</div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </template>
      <div class="rule-box">
        <p class="title f32 fw6">升级规则</p>
        <p class="rule f24">
          {{ rule }}
        </p>
      </div>
    </div>

    <!-- 权益卡列表 -->
    <van-popup v-model="isCardListShow" position="bottom" safe-area-inset-bottom class="pop-rule">
      <div class="pop-rule-title f32 fw6">
        选择升级的包月私教卡
        <van-icon name="cross" class="pop-rule-close f28" @click="closeCardList" />
      </div>
      <div class="pop-rule-content">
        <div class="top-tip f24 fw6 row-start-center">
          限时优惠福利，每个用户ID仅可升级一次
        </div>
        <div class="m-card col-between-start" @click="chooseUpgradeCard(item)" v-for="item in equityCardList"
          :key="item.equityCardUserId" :style="`background-image: url(${item.backgroundImgUrl})`">
          <p class="title f40 fw6">{{ item.cardName }}</p>
          <div class="tags row-start-center">
            <div :class="['tag', 'row-center-center', 'f20', 'fw6', item.status === 'ACTIVE' ? 'active' : '']">{{
              item.statusName }}</div>
            <div class="tag row-center-center f20 fw6">{{ item.useValidityStr }}</div>
            <div class="tag row-center-center f20 fw6">{{ item.usePlace }}</div>
            <div class="tag row-center-center f20 fw6">{{ item.levelStr }}</div>
          </div>
          <div :class="['checked-button', unEquityCardObj.equityCardUserId === item.equityCardUserId ? 'active' : '']">
          </div>
        </div>
      </div>
      <div class="pop-button">
        <div @click="confirmUpgradeCard" class="button row-center-center f28 fw6">确认</div>
      </div>
    </van-popup>

    <!-- 升级支付弹窗 -->
    <upgrade-pay-popup :show="isPayShow" :payData="upgradeCard" :equityCardUserId="equityCardObj.equityCardUserId"
      @close="closePopup"></upgrade-pay-popup>

    <!-- 升级成功 -->
    <!-- 权益卡列表 -->
    <van-popup v-model="isSuccessShow" position="bottom" safe-area-inset-bottom class="pop-success">
      <!-- <van-icon name="cross" class="pop-success-close" @click="isSuccessShow = false" /> -->
      <div class="col-start-center">
        <img src="https://img.chaolu.com.cn/ACT/sell-membership-2022/success-icon.png" alt="" class="icon">
        <p class="f32 fw6">您的包月私教卡已升级成功!</p>
        <div @click="goMyCardBag" class="button f28 fw6 row-center-center">去我的卡包查看</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import { newAppBack, initBack, appPaySuccess } from '@/lib/appMethod'
import headBar from '@/components/app/headBar'
import upgradePayPopup from './components/upgrade-pay-popup'
import { getParam } from '@/lib/utils'
import wx from "weixin-js-sdk"

export default {
  mixins: [userMixin],
  components: {
    headBar, upgradePayPopup
  },
  data() {
    return {
      isCardListShow: false,
      equityCardList: [], // 权益卡列表
      unEquityCardObj: {}, // 选择的权益卡 未确认
      equityCardObj: {}, // 权益卡对象 确认
      upTips: '',

      packageList: [], // 课包列表
      levelIndex: 0,
      upgradeCard: {}, // 升级成哪张卡
      isPayShow: false,

      inviteTeacherId: '',
      inviteUserId: '',

      isSuccessShow: false,
      rule: `1、针对拥有包月私教卡的用户，可自主选择升级为同档位更高规格天数或更高档位的包月私教卡；

            2、包月私教卡升级后权益项一并升级，升级时，原包月私教卡已进行的权益失效，按升级后的包月私教卡重置生效，并根据补差价的形式购买所升级的包月私教卡；
            场景示例：用户使用100元优惠券购买240元包月私教月卡，其【标准价】2380元，已使用10天，【团课券权益】4张28元团课券均已使用，该卡现进行升级，区分升级的不同选择，具体如下，
            ① 同档位升级：240元包月私教月卡升级为【标准价】6580元的240元包月私教季卡
            需补升级差价金额为：（6580-100）/90*80-[（2380-100）/30］*（30-10）=4240

            ② 不同档位升级：240元包月私教月卡升级为【标准价】10680元的360元包月私教季卡
            需补升级差价金额为：（10680-100）/90*80-[（2380-100）/30］*（30-10）=7784.44

            3、处在冻结状态的包月私教卡无法升级，且每张包月私教卡仅支持升级一次，不可重复进行升级；

            4、每个用户拥有一次包月私教卡升级权限，若需要升级两张及以上的包月私教卡，需在APP里在线联系客服操作，如有其他疑问，可咨询或致电客服，客服电话：400-700-5678`,
    }
  },
  watch: {
    'equityCardObj.equityCardUserId'() {
      this.getPackageList()
      window.scrollTo({ top: 0, left: 0 })
    }
  },
  async created() {
    await this.$getAllInfo()
    initBack()

    // 若是教练邀请 获取教练id
    const q = this.$route.query
    this.shopId = q.id
    this.inviteTeacherId = q.teacherId || q.inviteTeacherId
    this.inviteUserId = q.inviteUserId
    // 绑定关系
    if (this.shopId) this.getBindInfo()
    // 升级成功
    if (q.code == 1) {
      setTimeout(() => {
        this.isSuccessShow = true
      }, 1500);
    } else {
      this.getEquityCard()
    }
    appPaySuccess().then(() => {
      setTimeout(() => {
        this.isSuccessShow = true
      }, 1500);
    })
  },
  methods: {
    newAppBack,
    getBindInfo() {
      this.$axios
        .post(this.baseURLApp + '/userDistribute/bindUser', {
          hasWx: true,
          activityNo: this.shopId, // shopId就是老接口里的activityNo
          userId: this.userId,
          saleTeacherId: this.inviteTeacherId,
          saleUserId: this.inviteUserId
        })
        .then((res) => {

        });
    },
    getEquityCard() {
      this.$axios.post(this.baseURLApp + '/user/equityCard/equityCardUserSearch/v2', {
        token: this.token,
        userId: this.userId,
        packageStatus: 'USE', // 课包可用
        type: 2, // 写死的 不懂的问服务端
        specialType: 'PER_UPGRADE' // 私教升级
      }).then((res) => {
        let r = res.data || []
        // if (r.length == 0) {
        //   this.equityCardObj = {
        //     backgroundImgUrl: 'https://img.chaolu.com.cn/ACT/sell-membership-2022/empty.png'
        //   }
        //   return
        // }
        this.equityCardList = r
        this.equityCardObj = r.filter(item => item.equityCardUserId == getParam().equityCardUserId)[0] || r[0]
        this.unEquityCardObj = JSON.parse(JSON.stringify(this.equityCardObj))
      })
    },
    getPackageList() {
      this.$axios.post(this.baseURLApp + '/equityCard/perEquityCardUpgradeMall', {
        token: this.token,
        userId: this.userId,
        equityCardUserId: this.equityCardObj.equityCardUserId
      }).then((res) => {
        const r = res.data.levels || []
        this.packageList = r
        this.upTips = res.data?.describe ?? ''
      })
    },
    changeLevel(i) {
      this.levelIndex = i
    },
    async upgrade(item) {
      item.name = `${item.itemName}(${item.useValidityStr.substr(0, item.useValidityStr.length - 2)})(${this.packageList[this.levelIndex].price}元档位)`
      this.upgradeCard = item
      const r = await this.upgradeCheck()
      r.data ? this.isPayShow = true : this.$toast(r.msg)
    },
    upgradeCheck() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: 'spinner',
      })
      return this.$axios.post(this.baseURLApp + '/equityCard/check/upgrade', {
        token: this.token,
        userId: this.userId,
        oldEquityCardUserId: this.equityCardObj.equityCardUserId,
        upgradeItemId: this.upgradeCard.itemId,
        upgradeSource: 'PER_UPGRADE'
      }).then((r) => {
        this.$toast.clear()
        return r
      })
    },
    closePopup() {
      this.isPayShow = false
    },
    openCardList() {
      this.isCardListShow = true
    },
    chooseUpgradeCard(item) {
      this.unEquityCardObj = item
    },
    confirmUpgradeCard() {
      this.levelIndex = 0
      this.isCardListShow = false
      this.equityCardObj = JSON.parse(JSON.stringify(this.unEquityCardObj))
    },

    goMyCardBag() {
      if (this.appTypeStr === 'mini') {
        wx.miniProgram.redirectTo({
          url: `/pages/webView/index?webUrl=${window.location.origin}/#/month-card/my-card&userId=1&token=1&type=2`
        })
      } else {
        this.$router.replace({
          path: `/month-card/my-card`,
          query: { type: 2 }
        })
      }
    },
    closeCardList() {
      this.isCardListShow = false
      this.unEquityCardObj = JSON.parse(JSON.stringify(this.equityCardObj))
    }
  },
}
</script>
<style lang="less" scoped>
.container {

  background: #F7F7F7;

  * {
    box-sizing: border-box;
  }
  .empty{
    text-align: center;
    font-size: 26px;
    img{
      width: 320px;
      height: 320px;
    }
    p{
      margin-top: 48px;
    }
  }

  .w-block {
    padding: 18px 32px 28px;
    background-color: #fff;

    .change-btn {
      width: 100%;
      height: 76px;
      background: #FFFFFF;
      border-radius: 8px;
      border: 1px solid #9AA1A9;
      margin: 28px 0 0;

      img {
        width: 32px;
      }
    }
  }

  .block {
    padding: 40px 32px;
    min-height: 70vh;

    .upgrade-box {
      background-color: #fff;
      border-radius: 16px;
      overflow: hidden;
      margin: 24px 0 0;

      .select-box {
        width: 100%;
        height: 88px;
        border-bottom: 1px solid #EEEEEE;

        .select-item {
          width: fit-content;
          min-width: 80px;
          height: 84px;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
          color: #6C727A;

          &.active {
            color: #242831;
            border-bottom: 4px solid #242831;
          }
        }
      }

      .package-box {

        .package-item {
          width: 100%;
          padding: 36px 28px;

          .card {
            width: 176px;
            height: 140px;
            border-radius: 12px;
            padding: 0 12px;
            margin-top: 6px;
            background-size: cover;
            background-position: center;
            color: #fff;
          }

          .right {
            margin: 0 0 0 24px;

            .tag {
              padding: 0 8px;
              height: 30px;
              border-radius: 4px;
              border: 0.5px solid #9AA1A9;
              margin-top: 8px;
            }

            .tag+.tag {
              margin-left: 16px;
            }

            .info {

              .price {
                margin: 18px 0 0;

                .left {
                  width: 40%;
                }

                .right {
                  width: 60%;
                  padding: 0 0 0 22px;
                  border-left: 1px solid #eee;

                  .p2 {
                    color: #F03C18;
                  }
                }

                .p2 {
                  margin: 4px 0 0;
                }
              }
            }
          }


          .btn {
            padding: 0 18px;
            height: 48px;
            background: #FFDE00;
            border-radius: 4px;
          }
        }

      }
    }

    .rule-box {
      margin: 56px 0 0;

      .rule {
        margin: 20px 0 0;
        line-height: 44px;
        white-space: pre-line;
      }
    }
  }

  .top-tip {
    width: 100%;
    height: 68px;
    color: #FF6E00;
  }

  .m-card {
    width: 100%;
    height: 320px;
    border-radius: 14px;
    padding: 24px 28px 28px 28px;
    background-size: 100% 100%;
    position: relative;

    .title {
      color: #fff;
      // text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
      -webkit-background-clip: text;
    }

    .tags {
      .tag {
        padding: 0 12px;
        height: 36px;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 4px 4px 4px 4px;
        margin: 0 16px 0 0;
        color: #fff;

        &.active {
          color: #FFDE00;
        }
      }
    }

    .checked-button {
      width: 44px;
      height: 44px;
      position: absolute;
      right: 32px;
      top: 32px;
      background: #FFFFFF;
      border-radius: 50%;
      background-size: cover;

      &.active {
        background-image: url(https://img.chaolu.com.cn/ACT/sell-membership-2022/checked-icon.png);
      }
    }
  }

  .pop-rule {
    border-radius: 24px 24px 0 0;
    color: #242831;

    .pop-rule-title {
      height: 120px;
      text-align: center;
      line-height: 130px;
      position: relative;

      .pop-rule-close {
        position: absolute;
        right: 32px;
        top: 38px;
        width: 44px;
        height: 44px;
        line-height: 44px;
      }
    }

    .pop-rule-content {
      padding: 0 32px;
      max-height: 60vh;
      overflow: auto;
      padding-bottom: 60px;

      .m-card {
        margin: 0 0 36px;
      }
    }

    .pop-button {
      padding: 24px 32px;
      border-top: 1px solid #DDDDDD;

      .button {
        width: 686px;
        height: 88px;
        background: #FFDE00;
        border-radius: 8px 8px 8px 8px;
      }
    }
  }


  .pop-success {
    width: 100%;
    height: 100vh;
    color: #242831;
    background-color: #fff;
    padding: calc(100px + env(safe-area-inset-top)) 32px 0;

    .pop-success-close {
      font-size: 56px;
    }

    .icon {
      width: 88px;
      margin: 32px 0 0;
    }

    p {
      margin: 40px 0 0;
    }

    .button {
      width: 426px;
      height: 88px;
      background: #FFDE00;
      border-radius: 8px 8px 8px 8px;
      margin: 120px 0 0;
    }
  }
}
</style>
